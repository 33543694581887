

import React from 'react';
import { Container, Row, Col } from "react-bootstrap";
import styled from 'styled-components';
import { Title, Section, Box, Button, Span, Text } from "../../components/Core";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

 
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';



const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const SecondText = styled.h3`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
    align-items: center;
    margin: 0 auto;
    justify-content: center;
    text-align: center;
  }
`;


export default function Peelfaq() {
    return (
      <>
      
        <Section py={4} id="faq" bg="#ffffff" className="">
      <Container className="pb-lg-5 mb pt-lg-5 pt-3 pb-3">
        <Row className="justify-content-center pb-4">
          <Col lg="12">
   
            <Iwrap>


<Itext as="h2">Questions about <span>Acne Peel</span></Itext>
</Iwrap>
      <SecondText>
      Frequently Asked Questions

      </SecondText>
         
          </Col>
        </Row>


        <Row className="">
       
       



        <Col
     
     >
         
         <Accordion allowZeroExpanded>

<AccordionItem>
  <AccordionItemHeading>
    <AccordionItemButton>
      Why choose Skinpeel for Acne?
    </AccordionItemButton>
  </AccordionItemHeading>
  <AccordionItemPanel>
    <p>
      - Skinpeel is a medically licensed acne treatment<br/>
      - Addresses all underlying causes of acne<br/>
      - Treats all visible and invisible symptoms<br/>
      - Clinical studies carried out by eminent dermatologists<br/>
      - Long term acne solution
    </p>
    <p>
      Oil and dead skin clog hair follicles as a result of increased oil production and thicker skin, providing acne bacteria with the ideal breeding ground. For the long-term relief of comedogenic and inflammatory acne, SkinMed® is medically approved to treat all outward signs and underlying causes of acne by reducing oil production, bacterial growth, and inflammation.
    </p>
  </AccordionItemPanel>
</AccordionItem>

</Accordion> <br />

<Accordion allowZeroExpanded>

<AccordionItem>
  <AccordionItemHeading>
    <AccordionItemButton>
      How does Skinpeel for Acne work?
    </AccordionItemButton>
  </AccordionItemHeading>
  <AccordionItemPanel>
    <p>
      EnerPeel® Salicylic Acid has received medical approval for the successful treatment of comedogenic and inflammatory acne as well as resistant and mature onset acne, making it the perfect acne treatment London. This salicylic peel also effectively combats infected and inflammatory lesions. This procedure is non-invasive and is applied to the skin gently and evenly. It will feel like a light serum on your skin and once applied and settled, you will feel a tingling sensation before we remove it.
    </p>
    <p>
      NO heat<br/>
      NO injections<br/>
      NO surgery<br/>
      Salicylic Peel is perfect for controlling and healing acne. Delivering a powerful dose of anti-bacterial, anti-inflammatory, and sebum-controlling salicylic acid, the peel exfoliates, opens the sebaceous ducts, neutralises bacteria, and calms and reduces redness.
    </p>
    <p>
      The appointment consists of a consultation whereby our therapist will closely assess the condition of your skin and examine your acne to understand your concern and prescribe the best course of treatment and a skincare programme. If deemed suitable and you are happy to proceed, the treatment will then commence straight after.
      <ol>
        <li>Face will be cleansed</li>
        <li>Peel will be applied for a few minutes (avg. 5 mins)</li>
        <li>Peel is removed</li>
      </ol>
      If unsure, we do provide a free skin consultation (for a limited time only) that you can attend before you book in for the treatment.
    </p>
  </AccordionItemPanel>
</AccordionItem>

</Accordion><br />

<Accordion allowZeroExpanded>

<AccordionItem>
  <AccordionItemHeading>
    <AccordionItemButton>
      How many treatments are needed?
    </AccordionItemButton>
  </AccordionItemHeading>
  <AccordionItemPanel>
    <p>
      Depending on the severity of acne, a course of 3-6 peels will need to be administered. Each session will need 14 days space between each. During the personalised consultation, we will go through a full assessment of your skin to determine how many sessions may be needed.
    </p>
    <p>
      The treatment we recommend for you depends on how serious your condition is—whether it's inflammatory acne, lesions, nodules, or cystic acne, or even acne scars. Even if you believe your acne is too painful and severe to be treated, our acne specialists will offer advice on the finest skincare products and treatments for your particular acne and skin type.
    </p>
  </AccordionItemPanel>
</AccordionItem>

</Accordion><br />

<Accordion allowZeroExpanded>

<AccordionItem>
  <AccordionItemHeading>
    <AccordionItemButton>
      How is Acne developed?
    </AccordionItemButton>
  </AccordionItemHeading>
  <AccordionItemPanel>
    <p>
      A hormonally sensitive skin losing its capacity to sustain regular skin water loss is the fundamental cause of acne. The obstruction of the hair follicle duct is caused by the skin's reaction to excessive trans epidermal water loss, which also causes bacterial colonising, breeding, and feeding under the plug, which results in inflammation and the spot.
    </p>
    <p>
      Dihydrotestosterone (DHT), a hormone continuously generated as part of the natural breakdown of testosterone throughout the body, is made more sensitive to the skin by underlying physiological changes.
    </p>
    <p>
      At certain life stages, the skin becomes aware of and sensitised to DHT hormone and this causes a depletion of skin fatty acids; linoleic acid in particular. Sensitivity appears to occur with normal hormonal balance. In 100% of men and 75% of women, there is no change to hormonal levels before, during, or after the acne phase. Apart from women with polycystic ovary syndrome.
    </p>
    <p>
      This depletion can cause up to a 10 fold reduction in linoleic acid content in the skin and sebum which compromises barrier function increasing trans epidermal water loss (skin loses water faster)
    </p>
    <p>
      The skin responds to this increase in trans epidermal water loss
      <ol>
        <li>The sebaceous gland in hair follicle increases sebum production to compensate (hyper- seborrhea) - Slows water loss</li>
        <li>Increased keratin deposition in skin, thickens and makes skin more impermeable - Slows water loss</li>
        <li>Skin cell production and also cell turnover accelerates to thicken the stratum corneum (SC) but as epidermal also line the hair follicle duct (pilo-sebaceous opening) then this causes a narrowing of the duct - Slows water loss</li>
      </ol>
      So in summary, faster skin cell turnover results in more dead skin on the surface. These are mixing with increased oil production trying to get out of a narrowing duct. The dead skin merge with oil, this leads to plugs forming in the hair follicle duct opening – causing acne breakouts.
    </p>
  </AccordionItemPanel>
</AccordionItem>

</Accordion>



        
</Col>



        </Row>

 


<br /> 
              <a href 
                                            ="https://connect.pabau.com/bookings.php?compid=8607" 
                                            activeClassName="active"
                                           
                                          
                                        >      <Bookbutton>
                
                
                Book Appointment
                
              
                </Bookbutton> </a>
        


       


        </Container>
        </Section>

        </>


    );
}








